import React from 'react'
import {Box, Container, Heading, Text} from "theme-ui"
import SectionHeading from '../components/section-heading'
import Layout from '../components/layout'

const policy = `Last updated February 19, 2021

    Thank you for choosing to be part of our community at Roam Garden ("Company", "we", "us",
    "our"). We are committed to protecting your personal information and your right to privacy. If
    you have any questions or concerns about this privacy notice, or our practices with regards to
    your personal information, please contact us at help@roam.garden.

    When you visit our website https://roam.garden (the "Website"), and more generally, use any of
    our services (the "Services", which include the Website), we appreciate that you are trusting us
    with your personal information. We take your privacy very seriously. In this privacy notice, we
    seek to explain to you in the clearest way possible what information we collect, how we use it
    and what rights you have in relation to it. We hope you take some time to read through it
    carefully, as it is important. If there are any terms in this privacy notice that you do not
    agree with, please discontinue use of our Services immediately.

    This privacy notice applies to all information collected through our Services (which, as
    described above, includes our Website), as well as, any related services, sales, marketing or
    events.

    Please read this privacy notice carefully as it will help you understand what we do with the
    information that we collect.

    TABLE OF CONTENTS

    1. WHAT INFORMATION DO WE COLLECT?
    2. HOW DO WE USE YOUR INFORMATION?
    3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?
    4. WHO WILL YOUR INFORMATION BE SHARED WITH?
    5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?
    6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?
    7. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?
    8. HOW LONG DO WE KEEP YOUR INFORMATION?
    9. HOW DO WE KEEP YOUR INFORMATION SAFE?
    10. DO WE COLLECT INFORMATION FROM MINORS?
    11. WHAT ARE YOUR PRIVACY RIGHTS?
    12. CONTROLS FOR DO-NOT-TRACK FEATURES
    13. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?
    14. DO WE MAKE UPDATES TO THIS NOTICE?
    15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?
    16. HOW CAN YOU REVIEW, UPDATE OR DELETE THE DATA WE COLLECT FROM YOU?

    1. WHAT INFORMATION DO WE COLLECT?

    Personal information you disclose to us

    In Short: We collect personal information that you provide to us.

    We collect personal information that you voluntarily provide to us when you register on the
    Website, express an interest in obtaining information about us or our products and Services,
    when you participate in activities on the Website or otherwise when you contact us.

    The personal information that we collect depends on the context of your interactions with us and
    the Website, the choices you make and the products and features you use. The personal
    information we collect may include the following:

    Personal Information Provided by You. We collect email addresses; usernames; passwords; and
    other similar information.

    Payment Data. We may collect data necessary to process your payment if you make purchases, such
    as your payment instrument number (such as a credit card number), and the security code
    associated with your payment instrument. All payment data is stored by Stripe. You may find
    their privacy notice link(s) here: https://stripe.com/privacy.

    Social Media Login Data. We may provide you with the option to register with us using your
    existing social media account details, like your Facebook, Twitter or other social media
    account. If you choose to register in this way, we will collect the information described in the
    section called "HOW DO WE HANDLE YOUR SOCIAL LOGINS" below.

    All personal information that you provide to us must be true, complete and accurate, and you
    must notify us of any changes to such personal information.

    Information automatically collected

    In Short: Some information — such as your Internet Protocol (IP) address and/or browser and
    device characteristics — is collected automatically when you visit our Website.

    We automatically collect certain information when you visit, use or navigate the Website. This
    information does not reveal your specific identity (like your name or contact information) but
    may include device and usage information, such as your IP address, browser and device
    characteristics, operating system, language preferences, referring URLs, device name, country,
    location, information about how and when you use our Website and other technical information.
    This information is primarily needed to maintain the security and operation of our Website, and
    for our internal analytics and reporting purposes.

    Like many businesses, we also collect information through cookies and similar technologies.

    The information we collect includes:
    Log and Usage Data. Log and usage data is service-related, diagnostic, usage and performance
    information our servers automatically collect when you access or use our Website and which we
    record in log files. Depending on how you interact with us, this log data may include your IP
    address, device information, browser type and settings and information about your activity in
    the Website (such as the date/time stamps associated with your usage, pages and files viewed,
    searches and other actions you take such as which features you use), device event information
    (such as system activity, error reports (sometimes called 'crash dumps') and hardware settings).

    2. HOW DO WE USE YOUR INFORMATION?

    In Short: We process your information for purposes based on legitimate business interests, the
    fulfillment of our contract with you, compliance with our legal obligations, and/or your
    consent.

    We use personal information collected via our Website for a variety of business purposes
    described below. We process your personal information for these purposes in reliance on our
    legitimate business interests, in order to enter into or perform a contract with you, with your
    consent, and/or for compliance with our legal obligations. We indicate the specific processing
    grounds we rely on next to each purpose listed below.

    We use the information we collect or receive:
    To facilitate account creation and logon process. If you choose to link your account with us to
    a third-party account (such as your Google or Facebook account), we use the information you
    allowed us to collect from those third parties to facilitate account creation and logon process
    for the performance of the contract. See the section below headed "HOW DO WE HANDLE YOUR SOCIAL
    LOGINS" for further information.
    To post testimonials. We post testimonials on our Website that may contain personal information.
    Prior to posting a testimonial, we will obtain your consent to use your name and the content of
    the testimonial. If you wish to update, or delete your testimonial, please contact us at
    privacy@roam.garden and be sure to include your name, testimonial location, and contact
    information.
    Request feedback. We may use your information to request feedback and to contact you about your
    use of our Website.
    To enable user-to-user communications. We may use your information in order to enable
    user-to-user communications with each user's consent.
    To manage user accounts. We may use your information for the purposes of managing our account
    and keeping it in working order.
    To send administrative information to you. We may use your personal information to send you
    product, service and new feature information and/or information about changes to our terms,
    conditions, and policies.
    To protect our Services. We may use your information as part of our efforts to keep our Website
    safe and secure (for example, for fraud monitoring and prevention).
    To enforce our terms, conditions and policies for business purposes, to comply with legal and
    regulatory requirements or in connection with our contract.
    To respond to legal requests and prevent harm. If we receive a subpoena or other legal request,
    we may need to inspect the data we hold to determine how to respond.

    Fulfill and manage your orders. We may use your information to fulfill and manage your orders,
    payments, returns, and exchanges made through the Website.

    Administer prize draws and competitions. We may use your information to administer prize draws
    and competitions when you elect to participate in our competitions.

    To deliver and facilitate delivery of services to the user. We may use your information to
    provide you with the requested service.

    To respond to user inquiries/offer support to users. We may use your information to respond to
    your inquiries and solve any potential issues you might have with the use of our Services.
    To send you marketing and promotional communications. We and/or our third-party marketing
    partners may use the personal information you send to us for our marketing purposes, if this is
    in accordance with your marketing preferences. For example, when expressing an interest in
    obtaining information about us or our Website, subscribing to marketing or otherwise contacting
    us, we will collect personal information from you. You can opt-out of our marketing emails at
    any time (see the "WHAT ARE YOUR PRIVACY RIGHTS" below).
    Deliver targeted advertising to you. We may use your information to develop and display
    personalized content and advertising (and work with third parties who do so) tailored to your
    interests and/or location and to measure its effectiveness.

    3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?

    In Short: We only share information with your consent, to comply with laws, to provide you with
    services, to protect your rights, or to fulfill business obligations.

    We may process or share your data that we hold based on the following legal basis:
    Consent: We may process your data if you have given us specific consent to use your personal
    information for a specific purpose.
    Legitimate Interests: We may process your data when it is reasonably necessary to achieve our
    legitimate business interests.
    Performance of a Contract: Where we have entered into a contract with you, we may process your
    personal information to fulfill the terms of our contract.
    Legal Obligations: We may disclose your information where we are legally required to do so in
    order to comply with applicable law, governmental requests, a judicial proceeding, court order,
    or legal process, such as in response to a court order or a subpoena (including in response to
    public authorities to meet national security or law enforcement requirements).
    Vital Interests: We may disclose your information where we believe it is necessary to
    investigate, prevent, or take action regarding potential violations of our policies, suspected
    fraud, situations involving potential threats to the safety of any person and illegal
    activities, or as evidence in litigation in which we are involved.
    More specifically, we may need to process your data or share your personal information in the
    following situations:
    Business Transfers. We may share or transfer your information in connection with, or during
    negotiations of, any merger, sale of company assets, financing, or acquisition of all or a
    portion of our business to another company.
    Vendors, Consultants and Other Third-Party Service Providers. We may share your data with
    third-party vendors, service providers, contractors or agents who perform services for us or on
    our behalf and require access to such information to do that work. Examples include: payment
    processing, data analysis, email delivery, hosting services, customer service and marketing
    efforts. We may allow selected third parties to use tracking technology on the Website, which
    will enable them to collect data on our behalf about how you interact with our Website over
    time. This information may be used to, among other things, analyze and track data, determine the
    popularity of certain content, pages or features, and better understand online activity. Unless
    described in this notice, we do not share, sell, rent or trade any of your information with
    third parties for their promotional purposes. We have contracts in place with our data
    processors, which are designed to help safeguard your personal information. This means that they
    cannot do anything with your personal information unless we have instructed them to do it. They
    will also not share your personal information with any organization apart from us. They also
    commit to protect the data they hold on our behalf and to retain it for the period we instruct.
    Business Partners. We may share your information with our business partners to offer you certain
    products, services or promotions.

    4. WHO WILL YOUR INFORMATION BE SHARED WITH?

    In Short: We only share information with the following third parties.

    We only share and disclose your information with the following third parties. We have
    categorized each party so that you may be easily understand the purpose of our data collection
    and processing practices. If we have processed your data based on your consent and you wish to
    revoke your consent, please contact us using the contact details provided in the section below
    titled "HOW CAN YOU CONTACT US ABOUT THIS NOTICE?".
    Advertising, Direct Marketing, and Lead Generation
    Google AdSense
    Cloud Computing Services
    Amazon Web Services (AWS)
    Invoice and Billing
    Stripe
    Website Hosting
    Netlify

    5. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?

    In Short: We may use cookies and other tracking technologies to collect and store your
    information.

    We may use cookies and similar tracking technologies (like web beacons and pixels) to access or
    store information. Specific information about how we use such technologies and how you can
    refuse certain cookies is set out in our Cookie Notice.

    6. HOW DO WE HANDLE YOUR SOCIAL LOGINS?

    In Short: If you choose to register or log in to our services using a social media account, we
    may have access to certain information about you.

    Our Website offers you the ability to register and login using your third-party social media
    account details (like your Facebook or Twitter logins). Where you choose to do this, we will
    receive certain profile information about you from your social media provider. The profile
    information we receive may vary depending on the social media provider concerned, but will often
    include your name, email address, friends list, profile picture as well as other information you
    choose to make public on such social media platform.

    We will use the information we receive only for the purposes that are described in this privacy
    notice or that are otherwise made clear to you on the relevant Website. Please note that we do
    not control, and are not responsible for, other uses of your personal information by your
    third-party social media provider. We recommend that you review their privacy notice to
    understand how they collect, use and share your personal information, and how you can set your
    privacy preferences on their sites and apps.

    7. IS YOUR INFORMATION TRANSFERRED INTERNATIONALLY?

    In Short: We may transfer, store, and process your information in countries other than your own.

    Our servers are located in United States. If you are accessing our Website from outside United
    States, please be aware that your information may be transferred to, stored, and processed by us
    in our facilities and by those third parties with whom we may share your personal information
    (see "WILL YOUR INFORMATION BE SHARED WITH ANYONE?" above), in and other countries.

    If you are a resident in the European Economic Area, then these countries may not necessarily
    have data protection laws or other similar laws as comprehensive as those in your country. We
    will however take all necessary measures to protect your personal information in accordance with
    this privacy notice and applicable law.

    European Commission's Standard Contractual Clauses:

    We have implemented measures to protect your personal information, including by using the
    European Commission's Standard Contractual Clauses for transfers of personal information between
    our group companies and between us and our third-party providers. These clauses require all
    recipients to protect all personal information that they process originating from the EEA in
    accordance with European data protection laws and regulations. Our Standard Contractual Clauses
    can be provided upon request. We have implemented similar appropriate safeguards with our
    third-party service providers and partners and further details can be provided upon request.

    8. HOW LONG DO WE KEEP YOUR INFORMATION?

    In Short: We keep your information for as long as necessary to fulfill the purposes outlined in
    this privacy notice unless otherwise required by law.

    We will only keep your personal information for as long as it is necessary for the purposes set
    out in this privacy notice, unless a longer retention period is required or permitted by law
    (such as tax, accounting or other legal requirements). No purpose in this notice will require us
    keeping your personal information for longer than the period of time in which users have an
    account with us.

    When we have no ongoing legitimate business need to process your personal information, we will
    either delete or anonymize such information, or, if this is not possible (for example, because
    your personal information has been stored in backup archives), then we will securely store your
    personal information and isolate it from any further processing until deletion is possible.

    9. HOW DO WE KEEP YOUR INFORMATION SAFE?

    In Short: We aim to protect your personal information through a system of organizational and
    technical security measures.

    We have implemented appropriate technical and organizational security measures designed to
    protect the security of any personal information we process. However, despite our safeguards and
    efforts to secure your information, no electronic transmission over the Internet or information
    storage technology can be guaranteed to be 100% secure, so we cannot promise or guarantee that
    hackers, cybercriminals, or other unauthorized third parties will not be able to defeat our
    security, and improperly collect, access, steal, or modify your information. Although we will do
    our best to protect your personal information, transmission of personal information to and from
    our Website is at your own risk. You should only access the Website within a secure environment.

    10. DO WE COLLECT INFORMATION FROM MINORS?

    In Short: We do not knowingly collect data from or market to children under 18 years of age.

    We do not knowingly solicit data from or market to children under 18 years of age. By using the
    Website, you represent that you are at least 18 or that you are the parent or guardian of such a
    minor and consent to such minor dependent’s use of the Website. If we learn that personal
    information from users less than 18 years of age has been collected, we will deactivate the
    account and take reasonable measures to promptly delete such data from our records. If you
    become aware of any data we may have collected from children under age 18, please contact us at
    privacy@roam.garden.

    11. WHAT ARE YOUR PRIVACY RIGHTS?

    In Short: In some regions, such as the European Economic Area, you have rights that allow you
    greater access to and control over your personal information. You may review, change, or
    terminate your account at any time.

    In some regions (like the European Economic Area), you have certain rights under applicable data
    protection laws. These may include the right (i) to request access and obtain a copy of your
    personal information, (ii) to request rectification or erasure; (iii) to restrict the processing
    of your personal information; and (iv) if applicable, to data portability. In certain
    circumstances, you may also have the right to object to the processing of your personal
    information. To make such a request, please use the contact details provided below. We will
    consider and act upon any request in accordance with applicable data protection laws.

    If we are relying on your consent to process your personal information, you have the right to
    withdraw your consent at any time. Please note however that this will not affect the lawfulness
    of the processing before its withdrawal, nor will it affect the processing of your personal
    information conducted in reliance on lawful processing grounds other than consent.

    If you are a resident in the European Economic Area and you believe we are unlawfully processing
    your personal information, you also have the right to complain to your local data protection
    supervisory authority. You can find their contact details here:
    http://ec.europa.eu/justice/data-protection/bodies/authorities/index_en.htm.

    If you are a resident in Switzerland, the contact details for the data protection authorities
    are available here: https://www.edoeb.admin.ch/edoeb/en/home.html.

    If you have questions or comments about your privacy rights, you may email us at
    privacy@roam.garden.

    Account Information

    If you would at any time like to review or change the information in your account or terminate
    your account, you can:
    Contact us using the contact information provided.
    Upon your request to terminate your account, we will deactivate or delete your account and
    information from our active databases. However, we may retain some information in our files to
    prevent fraud, troubleshoot problems, assist with any investigations, enforce our Terms of Use
    and/or comply with applicable legal requirements.

    Cookies and similar technologies: Most Web browsers are set to accept cookies by default. If you
    prefer, you can usually choose to set your browser to remove cookies and to reject cookies. If
    you choose to remove cookies or reject cookies, this could affect certain features or services
    of our Website. To opt-out of interest-based advertising by advertisers on our Website visit
    http://www.aboutads.info/choices/.

    Opting out of email marketing: You can unsubscribe from our marketing email list at any time by
    clicking on the unsubscribe link in the emails that we send or by contacting us using the
    details provided below. You will then be removed from the marketing email list — however, we may
    still communicate with you, for example to send you service-related emails that are necessary
    for the administration and use of your account, to respond to service requests, or for other
    non-marketing purposes. To otherwise opt-out, you may:
    Contact us using the contact information provided.

    12. CONTROLS FOR DO-NOT-TRACK FEATURES

    Most web browsers and some mobile operating systems and mobile applications include a
    Do-Not-Track ("DNT") feature or setting you can activate to signal your privacy preference not
    to have data about your online browsing activities monitored and collected. At this stage no
    uniform technology standard for recognizing and implementing DNT signals has been finalized. As
    such, we do not currently respond to DNT browser signals or any other mechanism that
    automatically communicates your choice not to be tracked online. If a standard for online
    tracking is adopted that we must follow in the future, we will inform you about that practice in
    a revised version of this privacy notice.

    13. DO CALIFORNIA RESIDENTS HAVE SPECIFIC PRIVACY RIGHTS?

    In Short: Yes, if you are a resident of California, you are granted specific rights regarding
    access to your personal information.

    California Civil Code Section 1798.83, also known as the "Shine The Light" law, permits our
    users who are California residents to request and obtain from us, once a year and free of
    charge, information about categories of personal information (if any) we disclosed to third
    parties for direct marketing purposes and the names and addresses of all third parties with
    which we shared personal information in the immediately preceding calendar year. If you are a
    California resident and would like to make such a request, please submit your request in writing
    to us using the contact information provided below.

    If you are under 18 years of age, reside in California, and have a registered account with the
    Website, you have the right to request removal of unwanted data that you publicly post on the
    Website. To request removal of such data, please contact us using the contact information
    provided below, and include the email address associated with your account and a statement that
    you reside in California. We will make sure the data is not publicly displayed on the Website,
    but please be aware that the data may not be completely or comprehensively removed from all our
    systems (e.g. backups, etc.).

    14. DO WE MAKE UPDATES TO THIS NOTICE?

    In Short: Yes, we will update this notice as necessary to stay compliant with relevant laws.

    We may update this privacy notice from time to time. The updated version will be indicated by an
    updated "Revised" date and the updated version will be effective as soon as it is accessible. If
    we make material changes to this privacy notice, we may notify you either by prominently posting
    a notice of such changes or by directly sending you a notification. We encourage you to review
    this privacy notice frequently to be informed of how we are protecting your information.

    15. HOW CAN YOU CONTACT US ABOUT THIS NOTICE?

    If you have questions or comments about this notice, you may email us at privacy@roam.garden

    16. HOW CAN YOU REVIEW, UPDATE, OR DELETE THE DATA WE COLLECT FROM YOU?

    Based on the applicable laws of your country, you may have the right to request access to the
    personal information we collect from you, change that information, or delete it in some
    circumstances. To request to review, update, or delete your personal information, please visit:
    privacy@roam.garden. We will respond to your request within 30 days.`


const QnA = [
    {
        question: "What happens to my graph after you're done building the site from it?",
        answer: "It can be stored in the place of upload (AWS S3) for up to 2 days and then deleted. " +
            "This is driven by the minimal retention period of AWS S3.",
    },
    {
        question: "How are you going to use the data that is in my graph?",
        answer: "We don't use and have no plans to use the data from the graph for the purposes other than " +
            "building the site with the settings you've specified.",
    },

]

const Privacy = () => {
    return (
        <Layout>
            <Box as="section" id="success" sx={styles.section}>
                <Container>
                    <SectionHeading title="Privacy Notice"/>
                    <Heading as="h2">Q&A</Heading>

                    {QnA.map(({question, answer}) =>
                        <>
                            <Heading as="h3" sx={styles.h3}>{question}</Heading>
                            <Text sx={{whiteSpace: "pre-wrap"}}>{answer}</Text>
                        </>,
                    )}

                    <Heading as="h2">General notice</Heading>
                    <Text sx={{whiteSpace: "pre-line"}}>{policy}</Text>
                </Container>
            </Box>
        </Layout>)
}

export default Privacy

const styles = {
    section: {
        pt: "140px",
        pb: [12, null, null, null, null, 15],
    },
    h3: {
        fontSize: "1.1em",
    },
}
